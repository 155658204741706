<nav class="navbar navbar-default navbar-static-top" data-spy="affix">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="navbar-header page-scroll">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar">
                        <span class="sr-only">Toggle navigation</span>
                        <span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </span>
                    </button>
                    <a class="navbar-brand" routerLink="/home"><img src="/assets/img/logo.png" height="50px"
                            alt="Logo" /></a>
                </div>

                <!-- Collect the nav links, forms, and other content for toggling -->
                <div id="navbar" class="navbar-collapse collapse">
                    <ul class="nav navbar-nav navbar-right">
                        <li routerLinkActive="active">
                            <a routerLink="/home">Start <span class="caret"></span></a>

                        </li>
                        <!-- <li routerLinkActive="active">
                            <a routerLink="/hygiene">Hygienemaßnahmen <span class="caret"></span></a>

                        </li> -->
                        <li routerLinkActive="active">
                            <a routerLink="pricing">Leistungen und Preise <span class="caret"></span></a>

                        </li>
                        <li routerLinkActive="active">
                            <a routerLink="/about">Über mich <span class="caret"></span></a>

                        </li>

                        <li routerLinkActive="active">
                            <a routerLink="contact">Kontakt</a>
                        </li>
                    </ul>
                </div>
                <!-- /.navbar-collapse -->
            </div>
        </div>

    </div>
    <!-- /.container -->
</nav>