<section>
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="content-left wow fadeInLeft">
                    <img src="/assets/img/about-us/1.jpg" alt="" />
                </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="content-right wow fadeInRight">
                    <h2>Über mich</h2>
                    <p>Mein Name ist <b>Karin Steinmaurer</b> und ich bin als Masseurin für Sie da.</p>
                    <p>Schon sehr lange ist mir bewusst, um glücklich zu sein, muss ich mit meinen Händen arbeiten. Daher beschloss ich in meiner zweiten Berufswahl Masseurin zu werden. Seit 2014 arbeite ich in meinem Traumberuf. </p>
                    <p>Ich darf Sie als Masseurin unterstützen Ihrem Körper etwas zurückzugeben. Spannungen und Blockaden zu lösen und so Wohlbefinden zu erlangen. Regelmäßige Massagen schenken Ihnen Vitalität und dadurch tanken Sie Kraft und Energie um Ihren Alltag bewältigen zu können.</p>
                </div>
            </div>

            <div class="col-xs-12 col-sm-12 col-md-6">
                <div class="content-right wow fadeInRight">
                    <a routerLink="/contact" class="animated flipInY btn btn-primary">Kontaktieren Sie mich</a>
                </div>
            </div>
        </div>
    </div>
</section>