<section id="page-title" class="parallax">
    <div class="title-info">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 wow fadeInLeft">
                    <div class="page-title text-center">
                        <h1>Unternehmen</h1>
                        <ol class="breadcrumb">
                            <li><a routerLink="/home">Startseite</a></li>
                            <li><a routerLink="/pricing">Preise</a></li>
                            <li class="active">Unternehmen</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end title-info-->
</section>
<!--end page-title-->

<!-- Procedures -->
<section id="services">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-5 col-md-4">
                <div class="service-list single">
                    <div class="thumb">
                        <img src="/assets/img/services/3.jpg" alt="" />
                    </div>
                    <!-- '             <h2>Klassisch</h2>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                        deleniti atque corrupti quos dolores et quas molestias</p>' -->
                </div>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-8 wow fadeInLeft">
                <div class="service-details">
                    <h3>Ab 2 Stunden ab <sup>€</sup>110,-</h3>
                    <p>Sie als Chef möchten Ihren Mitarbeitern Vitalität und Wohlbefinden gönnen?
                        Mein Angebot ist es, Sie und Ihre Mitarbeiter regelmäßig im Firmengebäude zu massieren.
                    </p>
                    <p>Meine mobile Liege steht bereit!
                    </p>

                    <h3>Preise</h3>
                    <ul>
                        <li>Ab 2 Stunden</li>
                        <li>Je nach Dauer der Massagen</li>
                        <li>a' 25 Minuten 4 Termine oder</li>
                        <li>a' 50 Minuten 2 Termine</li>

                        <li><b><sup>€</sup>110,-</b> zzgl. Kilometergeld (€0,42/km)</li>
                    </ul>
                    <br />
                    <p><b>Wer kauft schon die Katze im Sack?</b> Vereinbaren Sie als Chef gleich einen kostenlosen
                        Probetermin
                        (a‘ 25 Minuten) für Ihre Massage. So können Sie sich einen Eindruck von mir und meiner Leistung
                        machen. Alles weitere können wir danach besprechen.
                    </p>
                    <br />
                    <a routerLink="/contact" class="btn btn-default">Kontakt</a>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Procedures -->

<app-others [showCompany]="false"></app-others>