<app-banner></app-banner>

<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 wow fadeInLeft">
                <div class="title-block text-center">
                    <h1>Karin Steinmaurer</h1>
                    <div class="line-block">
                        <span class="bullet"><i class="fa fa-leaf"></i></span>
                    </div>
                    <p>Massagen für Ihr Kreuzwohl</p>
                </div>

                <div class="title-block text-center">
                    <div class="line-block">
                        <span class="bullet"><i class="fa fa-quote-right"></i></span>
                    </div>
                    <p><b>"Die größte Ehre, die man einem Menschen antun kann, ist die, dass man zu ihm Vertrauen
                            hat.</b><br /> - Matthias Claudius</p>
                    <div class="line-block">
                        <span class="bullet"><i class="fa fa-quote-right"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-section></app-section>


<app-contact></app-contact>