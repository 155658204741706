<section id="contact-us">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 wow fadeInLeft">
                <div class="text-center">
                    <h1>Kontakt</h1>
                    <div class="line-block">
                        <span class="bullet"><i class="fa fa-leaf"></i></span>
                    </div>
                    <p>Sie haben eine Frage oder möchten einen Termin vereinbaren? Rufen Sie mich gerne an oder schreiben Sie
                        mir eine E-Mail.</p>
                    <p>Sollten Sie zu Ihrem vereinbarten Termin verhindert sein, bitte ich Sie höflichst um Absage.
                        Erfolgt diese nicht spätestens 24 Stunden vor dem Termin, wird eine Zeitaufwandsentschädigung
                        verrechnet.</p>

                    <p>Ausschließlich <b>Barzahlung</b> möglich! </p>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-xs-12 col-sm-12 col-md-4">
                <ul class="contact-left">
                    <li>
                        <div class="contact-list wow fadeInLeft">
                            <i class="fa fa-map-marker"></i>
                            <div class="contact-info">
                                <p>Apfelstraße 4<br /> 4621 Sipbachzell</p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="contact-list wow fadeInLeft">
                            <i class="fa fa-phone"></i>
                            <div class="contact-info">
                                <p>Telefon: <a href="tel:+4367762455324">+43 677 62455324</a></p>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="contact-list wow fadeInLeft">
                            <i class="fa fa-envelope"></i>
                            <div class="contact-info">
                                <p><a href="mailto:massage@kreuzwohl.at">massage@kreuzwohl.at</a></p>
                            </div>
                        </div>
                    </li>
                    <!-- <li>
                        <div class="contact-list wow fadeInLeft">
                            <i class="fa fa-clock-o"></i>
                            <div class="contact-info">
                                <p>Monday-Friday: 9am - 5pm <br> Saturday: 10am - 2pm</p>
                            </div>
                        </div>
                    </li> -->
                </ul>

            </div>

            <div class="col-xs-12 col-sm-12 col-md-8">
                <div class="contact-right">
                    <h2>Öffnungszeiten</h2>

                    <ul class="bussiness-hour wow fadeInRight">

                        <li>Montag - Donnerstag: <span class="pull-right">08:00 - 19:00</span></li>
                        <li>Freitag: <span class="pull-right">08:00 - 12:00</span></li>
                        <li><span>Ich bin ausschließlich nach <b>Terminvereinbarung</b> für Sie da!</span></li>

                    </ul>
                </div>
            </div>



        </div>
    </div>
</section>