<section id="page-title" class="parallax">
    <div class="title-info">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="page-title text-center">
                        <h1>Gutscheine</h1>
                        <ol class="breadcrumb">
                            <li><a routerLink="/home">Startseite</a></li>
                            <li><a routerLink="/pricing">Preise</a></li>
                            <li class="active">Gutscheine</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end title-info-->
</section>
<!--end page-title-->

<!-- Procedures -->
<section id="services">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-5 col-md-4 wow fadeInLeft">
                <div class="service-list single">
                    <div class="thumb">
                        <img src="/assets/img/services/4.jpg" alt="" />
                    </div>
                    <!-- '             <h2>Klassisch</h2>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                        deleniti atque corrupti quos dolores et quas molestias</p>' -->
                </div>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-8 wow fadeInLeft">
                <div class="service-details">
                    <h3>ab <sup>€</sup> 10,-</h3>
                    <ul>
                        <li>Als perfektes Geschenk</li>
                        <li>Machen Sie Ihren Liebsten eine Freude</li>
                        <li>Zu jedem Anlass</li>
                        <li>Als Dankeschön</li>
                        <li>Auch per Mail erhältlich</li>
                    </ul>
                    <br/>

                    <a routerLink="/contact" class="btn btn-default">Kontakt</a>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Procedures -->

<app-others [showCompany]="false"></app-others>