import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { BannerComponent } from './banner/banner.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { PricingComponent } from './pricing/pricing.component';
import { SectionComponent } from './section/section.component';
import { FooterComponent } from './footer/footer.component';
import { ImprintComponent } from './imprint/imprint.component';
import { ProductsModule } from './products/products.module';
import { HygieneComponent } from './hygiene/hygiene.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    TopBarComponent,
    BannerComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    PricingComponent,
    SectionComponent,
    FooterComponent,
    ImprintComponent,
    HygieneComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ProductsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
