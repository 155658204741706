<section class="section" id="about">
    <div class="container">
        <h1 class="adsimple-221087397">Impressum</h1>
        <p class="adsimple-221087397">Kreuzwohl<br />Einzelunternehmen<br />Inhaber: Karin Steinmaurer<br />Apfelstraße 4<br />4621 Sipbachzell <br />Oberösterreich</p>
        <p class="adsimple-221087397">
            <strong>Unternehmensgegenstand:</strong> Masseur<br />
            <!-- <strong>UID-Nummer:</strong> TODO<br /> -->
        </p>
        <p class="adsimple-221087397">
            <strong>E-Mail: </strong> <a
                href="mailto:massage@kreuzwohl.at">massage@kreuzwohl.at</a>
            <br /><strong>Website: </strong> <a href="/">www.kreuzwohl.at</a>
        </p>

        <p>Verantwortlich für den Inhalt: Karin Steinmaurer</p>

        <p class="adsimple-221087397">
            <strong>Zuständige Kammer:</strong> WKO OÖ<br />
            <strong>Berufsrechtliche Regelungen:</strong> Gewerbeordnung: <a href="https://www.ris.bka.gv.at" target="blank">www.ris.bka.gv.at</a></p>
        <p class="adsimple-221087397">
            <strong>Zuständige Aufsichtsbehörde:</strong> Bezirkshauptmannschaft Wels-Land<br />
            <strong>Berufsbezeichnung:</strong> Masseur<br />
            <strong>Verleihungsstaat:</strong> Österreich</p>


        <p>Verbraucher haben die Möglichkeit, Beschwerden an die Online-Streitbeilegungsplattform der EU-Kommission
            zu richten: http://ec.europa.eu/odr. Sie können allfällige Beschwerden auch an die oben angegebene
            E-Mail-Adresse richten. </p>


        <p><a href="/assets/dsgvo.pdf" target="_blank">Datenschutz</a></p>

    </div>
</section>