import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gift',
  templateUrl: './gift.component.html',
  styleUrls: ['./gift.component.scss']
})
export class GiftComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
