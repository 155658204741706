<div class="top-bar">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-xs-6">
                <div class="top-left">
                    <ul>
                        <li><i class="fa fa-phone"></i> <a href="tel:+4367762455324">+43 677 62455324</a></li>
                        <li><i class="fa fa-envelope-o"></i> <a href="mailto:massage@kreuzwohl.at"> massage@kreuzwohl.at</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-12 col-xs-6">
                <div class="top-right">
                    <ul class="social-media">
                        <li><a href="https://www.facebook.com/Kreuzwohl-109749661272015" target="_blank"><i class="fa fa-facebook"></i></a></li>
                        <!-- <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                        <li><a href="#"><i class="fa fa-youtube"></i></a></li> -->
                        <li><a href="https://www.instagram.com/kreuzwohl/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>