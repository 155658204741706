<div id="banner" class="carousel slide carousel-fade" data-ride="carousel" data-pause="false">

    <!-- Wrapper for slides -->
    <div class="carousel-inner" role="listbox">
        <div class="item active" style="background-image:url(/assets/img/banner/1.jpg);">
            <div class="caption-info">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-8 col-md-offset-2">
                            <div class="caption-info-inner text-center">
                                <h1 class="animated flipInX"><b>Entspannung gesucht? Massage gefällig?</b> Dann sind Sie
                                    bei mir genau richtig.</h1>
                                <!-- <p class="animated flipInX home-text-2"></p> -->
                                <p class="home-text-1"><b>Massagen lindern Ihre körperlichen Verspannungen gleichzeitig werden Stress und
                                    Blockaden abgebaut. Gönnen Sie sich Zeit für sich und Ihren Körper!</b></p>
                                <a routerLink="/about" class="animated flipInY btn btn-primary">Über mich</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- 
        <div class="item" style="background-image:url(img/banner/3.jpg);">
            <div class="caption-info">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-8 col-md-offset-2">
                            <div class="caption-info-inner text-center">
                                <h1 class="animated fadeInLeft">Give you unique relaxation experience</h1>
                                <p class="animated fadeInLeft">Lorem Ipsum is simply dummy text of the
                                    printing.<br />Lorem Ipsum has been the industry's</p>
                                <a href="reservation.html" class="animated fadeInRight btn btn-primary">Contact
                                    Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
    <!--end carousel-inner-->


    <!-- Controls -->
    <a class="control left" href="#banner" data-slide="prev"><i class="fa fa-long-arrow-left"></i></a>
    <a class="right control" href="#banner" data-slide="next"><i class="fa fa-long-arrow-right"></i></a>
</div>