import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-others',
  templateUrl: './others.component.html',
  styleUrls: ['./others.component.scss']
})
export class OthersComponent implements OnInit {

  @Input()
  showClassic = true;
  @Input()
  showFeet = true;
  @Input()
  showCompany = true;
  @Input()
  showGift = true;

  constructor() { }

  ngOnInit(): void {
    
  }

}
