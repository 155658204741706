<section class="section-padding bg" id="subscribe">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
                <div class="subscribe-content text-center">
                    <!-- <h1>Subscribe &amp; get amazing update</h1>
                    <form action="#" method="post">
                        <div class="input-group">
                            <input type="email" class="form-control" placeholder='Enter your email' />
                            <span class="input-group-addon">
                                <input type="submit" class="btn btn-default" value="subscribe" />
                            </span>
                        </div>

                    </form> -->
                </div>
            </div>
        </div>
    </div>
</section>