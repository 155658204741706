import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { HygieneComponent } from './hygiene/hygiene.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PricingComponent } from './pricing/pricing.component';
import { ClassicComponent } from './products/classic/classic.component';
import { CompaniesComponent } from './products/companies/companies.component';
import { FeetComponent } from './products/feet/feet.component';
import { GiftComponent } from './products/gift/gift.component';
import { KreuzwohlMassageComponent } from './products/kreuzwohlmassage/kreuzwohlmassage.component';

const routes: Routes = [
  { path: '', pathMatch: "full", redirectTo: "home" },
  { path: 'home', component: HomeComponent },
  { path: 'hygiene', component: HygieneComponent },
  { path: 'about', component: AboutComponent },
  { path: 'pricing', component: PricingComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'imprint', component: ImprintComponent },

  { path: 'products', pathMatch: "full", redirectTo: "products/classic" },
  { path: 'products/classic', component: ClassicComponent },
  { path: 'products/feet', component: FeetComponent },
  { path: 'products/companies', component: CompaniesComponent },
  { path: 'products/gift', component: GiftComponent },
  { path: 'products/kreuzwohlmassage', component: KreuzwohlMassageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
