<section id="page-title" class="parallax">
    <div class="title-info">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 wow fadeInLeft">
                    <div class="page-title text-center">
                        <h1>Fußreflexzonenmassage</h1>
                        <ol class="breadcrumb">
                            <li><a routerLink="/home">Startseite</a></li>
                            <li><a routerLink="/pricing">Preise</a></li>
                            <li class="active">Fußrefelexzonenmassage</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end title-info-->
</section>
<!--end page-title-->

<!-- Procedures -->
<section id="services">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-5 col-md-4 wow fadeInLeft">
                <div class="service-list single">
                    <div class="thumb">
                        <img src="/assets/img/services/2.jpg" alt="" />
                    </div>
                </div>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-8 wow fadeInLeft">
                <div class="service-details">
                    <h3>ab <sup>€</sup>49,-</h3>
                    <p>Durch das Anregen oder Beruhigen einer Zone am Fuß erfolgt Einwirkung auf das gesamte
                        Energiesystem. Energien werden harmonisiert und Selbstheilungskräfte können aktiv werden. Zum
                        Relaxen und Durchatmen gönnen Sie Ihren Füßen eine Auszeit, denn sie tragen Ihren Körper ein
                        Leben lang.</p>
                    <p>Ich empfehle Ihnen eine Fußreflexzonenmassage-Kur ca. 8 Behandlungen 1-2x jährlich zur Unterstützung Ihres
                        Stoffwechsels und Immunsystems.
                        Auch einzeln buchbar oder in Kombination mit einer Teilmassage.
                    </p>

                    <h3>Preise</h3>
                    <ul>
                        <li>Fußreflexzonenmassage - 40 Minuten <b><sup>€</sup>49,-</b></li>
                        <li>Fußreflexzonenmassage - 50 Minuten <b><sup>€</sup>58,-</b></li>
                        <li>Fußreflexzonen- und Teilmassage-Kombi - 50 Minuten <b><sup>€</sup>58,-</b></li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Procedures -->

<app-others [showFeet]="false"></app-others>