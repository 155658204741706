<section id="page-title" class="parallax">
    <div class="title-info">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 wow fadeInLeft">
                    <div class="page-title text-center">
                        <h1>Klassische Massagen</h1>
                        <ol class="breadcrumb">
                            <li><a routerLink="/home">Startseite</a></li>
                            <li><a routerLink="/pricing">Preise</a></li>
                            <li class="active">Klassische Massagen</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end title-info-->
</section>
<!--end page-title-->

<!-- Procedures -->
<section id="services">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-5 col-md-4 wow fadeInLeft">
                <div class="service-list single">
                    <div class="thumb">
                        <img src="/assets/img/services/1.jpg" alt="" />
                    </div>
                    <!-- '             <h2>Klassisch</h2>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                        deleniti atque corrupti quos dolores et quas molestias</p>' -->
                </div>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-8 wow fadeInLeft">
                <div class="service-details">
                    <h3>ab <sup>€</sup>35,-</h3>
                    <p>Ich empfehle Ihnen eine klassische Massage vorbeugend, in regelmäßigen Abständen um physisch
                        aktiv
                        zu bleiben. Natürlich wirkt eine klassische Massage immer wohltuend um den Körper Entspannung zu
                        gönnen. Als Teilmassage oder als Ganzkörpermassage.</p>
                    <ul>
                        <li>Wirkt durchblutungsfördernd und anregend.</li>
                        <li>Löst somit Verspannungen im Muskel.</li>
                        <li>Fördert den Stressabbau und wirkt positiv auf Ihre Psyche.</li>
                        <li>Sorgt für mehr Beweglichkeit und Vitalität im ganzen Körper.</li>
                    </ul>
                    <br />
                    <h3>Preise</h3>
                    <ul>
                        <li>25 Minuten <b><sup>€</sup>35,-</b></li>
                        <li>40 Minuten <b><sup>€</sup>49,-</b></li>
                        <li>50 Minuten <b><sup>€</sup>58,-</b></li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Procedures -->

<app-others [showClassic]="false"></app-others>