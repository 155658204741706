<footer id="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">

                <div class="col-xs-12 col-sm-3">
                    <div class="footer-widget">
                        <h2>Links</h2>
                        <ul>
                            <li><a routerLink="/about">Über mich</a></li>
                            <li><a routerLink="/pricing">Preise & Leistungen</a></li>
                            <li><a routerLink="/contact">Kontakt</a></li>
                            <li><a routerLink="/imprint">Impressum</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-3">
                    <div class="footer-widget">
                        <h2>Kontakt Info</h2>
                        <ul>
                            <li><i class="fa fa-send" aria-hidden="true"></i>Apfelstraße 4, 4621 Sipbachzell</li>
                            <li><i class="fa fa-phone" aria-hidden="true"></i> <a href="tel:+4367762455324">+43 677
                                    62455324</a> </li>
                            <li><i class="fa fa-envelope-o" aria-hidden="true"></i> <a
                                    href="mailto:massage@kreuzwohl.at">massage@kreuzwohl.at</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-3">
                    <div class="footer-widget">
                        <h2>Öffnungszeiten</h2>
                        <ul class="bussiness-hour">
                            <li>Montag - Donnerstag: <span class="pull-right">08:00 - 19:00</span></li>
                            <li>Freitag: <span class="pull-right">08:00 - 12:00</span></li>
                            <li><span>Ausschließlich nach <b>Terminvereinbarung</b></span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-3">
                    <div class="footer-widget">
                        <img src="/assets/img/siegel.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="copyright text-center">
                        <p>&copy; 2021 by Kreuzwohl - Karin Steinmaurer | All right reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<!-- Bact to top Section-->
<div class="back-top">
    <a href="#"><i class="fa fa-angle-up"></i></a>
</div>