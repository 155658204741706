<section id="pricing">
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div class="title-block text-center">
                    <h1>Meine Leistungen und Preise</h1>
                    <div class="line-block">
                        <span class="bullet"><i class="fa fa-leaf"></i></span>
                    </div>
                    <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elitsed eiusmod tempor enim minim veniam quis notru exercit</p> -->
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="price-table wow fadeInUp">
                    <div class="price-thumb">
                        <a routerLink="/products/classic"><img src="/assets/img/services/1.jpg" alt="" /></a>
                    </div>
                    <h1>Klassische Massagen</h1>
                    <div class="price">
                        <span>ab <sup>€</sup>35,-<sub></sub></span>
                    </div>
                    <ul class="pricing-list">
                        <li>Durchblutungsfördernd und anregend</li>
                        <li>Löst Verspannungen im Muskel</li>
                        <li>Fördert Stressabbau</li>
                        <li>Wirkt positiv auf die Psyche</li>
                        <li>Mehr Beweglichkeit</li>
                    </ul>
                    <a routerLink="/products/classic" class="btn btn-primary" data-toggle="modal"
                        data-target="#reservation">Mehr Infos</a>
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="price-table wow fadeInUp" data-wow-delay="0.2s">
                    <div class="price-thumb">
                        <a routerLink="/products/feet"><img src="/assets/img/services/2.jpg" alt="" /></a>
                    </div>
                    <h1>Fußreflexzonen</h1>
                    <div class="price">
                        <span>ab <sup>€</sup>49,-<sub></sub></span>
                    </div>
                    <ul class="pricing-list">
                        <li>Harmonisierung von Körper und Seele</li>
                        <li>Mobilisierung der Selbstheilungskräfte</li>
                        <li>Wirkt positiv auf die Psyche</li>
                        <li>Auszeit für Ihre Füße</li>
                        <li>Auch für Kinder</li>
                    </ul>
                    <a routerLink="/products/feet" class="btn btn-primary" data-toggle="modal"
                        data-target="#reservation">Mehr Infos</a>
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="price-table wow fadeInUp" data-wow-delay="0.4s">
                    <div class="price-thumb">
                        <a routerLink="/products/kreuzwohlmassage"><img src="/assets/img/services/3.jpg" alt="" /></a>
                    </div>
                    <h1>KREUZWOHLMASSAGE</h1>
                    <div class="price">
                        <span>ab <sup>€</sup>92,-<sub></sub></span>
                    </div>
                    <ul class="pricing-list">
                        <li>90 Minuten</li>
                        <li>Individuell auf Sie abgestimmt</li>
                        <li>Fußreflexzonenmassage</li>
                        <li>Klassische Massage</li>
                        <li>Schröpfen</li>
                    </ul>
                    <a routerLink="/products/kreuzwohlmassage" class="btn btn-primary" data-toggle="modal"
                        data-target="#reservation">Mehr Infos</a>
                </div>
            </div>

            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3">
                <div class="price-table wow fadeInUp" data-wow-delay="0.6s">
                    <div class="price-thumb">
                        <a routerLink="/products/gift"><img src="/assets/img/services/4.jpg" alt="" /></a>
                    </div>
                    <h1>Gutscheine</h1>
                    <div class="price">
                        <span>ab<sup>€</sup>10,-<sub></sub></span>
                    </div>
                    <ul class="pricing-list">
                        <li>Als perfektes Geschenk</li>
                        <li>Für Ihre Liebsten</li>
                        <li>Zu jedem Anlass</li>
                        <li>Als Dankeschön</li>
                        <li><a routerLink="/contact">Auch per Mail</a></li>

                    </ul>
                    <a routerLink="/products/gift" class="btn btn-primary" data-toggle="modal"
                        data-target="#reservation">Mehr Infos</a>
                </div>
            </div>
        </div>
    </div>
</section>