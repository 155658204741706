<!-- Related Procedures -->
<section class="section-padding inverse">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 wow fadeInLeft">
                <div class="title-block text-center">
                    <h1>Meine Leistungen und Preise</h1>
                    <div class="line-block">
                        <span class="bullet"><i class="fa fa-leaf"></i></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <ng-container *ngIf="showClassic">
                <div class="service-list col-md-4 wow fadeInLeft" data-wow-delay="0.2s">
                    <div class="thumb">
                        <a routerLink="/products/classic"><img src="/assets/img/services/1.jpg" height="250px"
                                alt="" /></a>
                    </div>
                    <h2>Klassische Massagen</h2>
                    <div class="price-text"><sup>ab €</sup>35,-<sub></sub></div>
                   
                    <a routerLink="/products/classic" class="btn btn-default">Mehr Infos</a>
                </div>
            </ng-container>

            <ng-container *ngIf="showFeet">
                <div class="service-list col-md-4 wow fadeInLeft" data-wow-delay="0.3s">
                    <div class="thumb">
                        <a routerLink="/products/feet"><img src="/assets/img/services/2.jpg" height="250px"
                                alt="" /></a>
                    </div>
                    <h2>Fußreflexzonenmassage</h2>
                    <div class="price-text"><sup>ab €</sup>49,-<sub></sub></div>
                   
                    <a routerLink="/products/feet" class="btn btn-default">Mehr Infos</a>
                </div>
            </ng-container>

            <ng-container *ngIf="showCompany">
                <div class="service-list col-md-4 wow fadeInRight" data-wow-delay="0.4s">
                    <div class="thumb">
                        <a routerLink="/products/kreuzwohlmassage"> <img src="/assets/img/services/3.jpg" height="250px"
                                alt="" /></a>
                    </div>
                    <h2>Kreuzwohlmassage</h2>
                    <div class="price-text"><sup>ab €</sup>92,-<sub></sub></div>
                   
                    <a routerLink="/products/kreuzwohlmassage" class="btn btn-default">Mehr Infos</a>
                </div>
            </ng-container>

            <ng-container *ngIf="showGift">

                <div class="service-list col-md-4 wow fadeInRight" data-wow-delay="0.5s">
                    <div class="thumb">
                        <a routerLink="/products/gift"><img src="/assets/img/services/4.jpg" height="250px"
                                alt="" /></a>
                    </div>
                    <h2>Gutscheine</h2>
                    <div class="price-text"><sup>ab €</sup>10,-<sub></sub></div>
                    <!-- <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium
                        voluptatum deleniti atque corrupti quos dolores et quas molestias</p> -->
                    <a routerLink="/products/gift" class="btn btn-default">Mehr Infos</a>
                </div>
            </ng-container>
        </div>

    </div>
</section>