<section id="page-title" class="parallax">
    <div class="title-info">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 wow fadeInLeft">
                    <div class="page-title text-center">
                        <h1>kreuzwohlmassage</h1>
                        <ol class="breadcrumb">
                            <li><a routerLink="/home">Startseite</a></li>
                            <li><a routerLink="/pricing">Preise</a></li>
                            <li class="active">Kreuzwohlmassage</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end title-info-->
</section>
<!--end page-title-->

<!-- Procedures -->
<section id="services">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-5 col-md-4">
                <div class="service-list single">
                    <div class="thumb">
                        <img src="/assets/img/services/3.jpg" alt="" />
                    </div>
                    <!-- '             <h2>Klassisch</h2>
                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum
                        deleniti atque corrupti quos dolores et quas molestias</p>' -->
                </div>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-8 wow fadeInLeft">
                <div class="service-details">
                    <p>Lassen Sie sich mit der Kreuzwohlmassage verwöhnen.
                    </p>
                    <p>
                        Es erwartet Sie eine Massage zusammengestellt aus Fußreflexzonenmassage, klassische Massage und
                        Schröpfen.
                    </p>
                    <p>Gönnen Sie sich und Ihrem Körper die pure Entspannung bei einer Fußreflexzonenmassage, damit alle
                        Organe Ihres Körpers mit Hilfe von Reflexpunkten aktiviert werden. </p>

                    <h3>Außerdem erwartet Sie eine klassische Massage Ihres ganzen Körpers also: </h3>
                    <ul>
                        <li>beider Beine und Füße </li>
                        <li>beider Arme, Schulter und Hände</li>
                        <li>ganzer Rücken und Nacken </li>
                        <li>oder als Teilmassage individuell auf Sie abgestimmt. </li>
                    </ul>
                    <p>
                        Dadurch wird Stressabbau gefördert und für mehr Beweglichkeit Ihres Körpers gesorgt.</p>
                    <br />
                    <p>Abgerundet wird die Kreuzwohlmassage mit Schröpfen. Diese Technik schafft Mehrdurchblutung der
                        einzelnen geschröpften Muskeln, es fördert Lockerung und Beweglichkeit Ihres Körpers.
                    </p>
                    <h3>Preis</h3>
                    <ul>
                        <li>90 Minuten <b><sup>€</sup>92,-</b></li>

                    </ul>
                    <br />
                    <a routerLink="/contact" class="btn btn-default">Kontakt</a>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Procedures -->

<app-others [showCompany]="false"></app-others>