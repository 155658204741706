<section class="main-content">
    <div class="container">
        <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-12 wow fadeInLeft">
                <div class="content-block">
                    <div class="single_post">
                        <div class="post_thumb">
                            <img src="/assets/img/hygiene.png" alt="" />
                        </div>
                        <!--end post thumb-->

                        <h2>Hygiene bietet Schutz vor Infektionen</h2>

                        <div class="post_desc">
                            <p>Einen großen Hygienestandard setze ich voraus, vor allem ist es mir ein großes Anliegen
                                die geltenden Corona Maßnahmen einzuhalten: Ich lasse mich, wie gesetzlich
                                vorgeschrieben, wöchentlich Testen um Sie zu schützen.</p>

                        </div>
                        <!--end post desc-->
                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-12 wow fadeInLeft">
                <div class="service-details">
                    <h3>Um sich bei mir verwöhnen lassen zu können, bitte die 3 G-Regel einhalten:</h3>

                    <ul>
                        <li><b>Getestet:</b> Nachweis über ein negatives Covid-19-Testergebnis (Selbsttest mit
                            digitaler Lösung 24 Stunden, Antigentest 48 Stunden, PCR-Test 72 Stunden)</li>
                        <li><b>Geimpft:</b> Nachweis über eine mit einem zentral zugelassenen Impfstoff gegen Covid-19,
                            ab dem 22. Tag nach der Erstimpfung bzw. wenn die Zweitimpfung erfolgt ist</li>
                        <li><b>Genesen</b> ärztliche Bestätigung oder Absonderungsbescheid über ein in den letzten 6
                            Monaten vor der vorgesehenen Testung erfolgte und zu diesem Zeitpunkt aktuell abgelaufene
                            Infektion</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-12 wow fadeInLeft">
                <div class="service-details">
                    <h3>Die bisherigen Schutzmaßnahmen gelten weiterhin:</h3>

                    <ul>
                        <li>FFP2-Maskenpflicht für Kunden in geschlossenen Räumen</li>
                        <li>Mindestabstand 2m gegenüber Personen, die nicht im gemeinsamen Haushalt leben (gilt nicht
                            zwischen dem Kunden und dem Dienstleistungserbringer)</li>
                        <li>Bei Kundenkontakt gilt eine FFP2-Maskenpflicht</li>
                        <li>Keine Konsumation von Speisen und Getränken</li>
                        <li>Pro Kunde 10 Quadratmeter</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-12 wow fadeInLeft">
                <div class="service-details">
                    <h2>Bitte Bleiben Sie Gesund, alles Liebe <br />Karin </h2>
                </div>
            </div>

        </div>
    </div>
</section>