import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsComponent } from './products.component';
import { ClassicComponent } from './classic/classic.component';
import { FeetComponent } from './feet/feet.component';
import { CompaniesComponent } from './companies/companies.component';
import { GiftComponent } from './gift/gift.component';
import { OthersComponent } from './others/others.component';
import { RouterModule } from '@angular/router';
import { KreuzwohlMassageComponent } from './kreuzwohlmassage/kreuzwohlmassage.component';



@NgModule({
  declarations: [
    ProductsComponent,
    ClassicComponent,
    FeetComponent,
    CompaniesComponent,
    GiftComponent,
    OthersComponent,
    KreuzwohlMassageComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    OthersComponent
  ]
})
export class ProductsModule { }
